import React, { useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link'


function ExtravaButton({link,title="العودة للصفحة الرئيسية",icon,type,func,clas,style}) {


  return (
    <a href={link} key={title}>
    <button className={`extrava-button ${clas}`} style={style} onClick={func}>

      
    {type === "home"?  <img className='effect-img' src="./imgs/icons/effect.png" alt="effect"/>:""}
    
    
    
    <span>{title}</span>
    <img className='icon-img' src={`./imgs/icons/${icon}`} alt={icon}/>
    
    
    
    </button>
    </a>
  )
}

export default ExtravaButton