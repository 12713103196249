import React from 'react'

function Heading({title,desc}) {
  return (
    <div className="heading">
          <span className="heading-desc d-block">{desc}</span>
          <h2 className="heading-title">{title}</h2>
        </div>
  )
}

export default Heading