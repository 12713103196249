import React from 'react'
import items from "../data/product.json";
import Heading from './Heading';
import { NeonS30 } from './Variables';
import { HashLink } from 'react-router-hash-link';

export function Product({ sectionTitle = "section title", desc="desc", content = items, }) {


    return (
      <div className="best-seller">
        <div className="container">
          <Heading title={sectionTitle} desc={desc} />
          {/* <h2 className="fs-1">{sectionTitle}</h2> */}
          <div className="row">
            {content.map(
              ({ id, title, subtitle, desc, img, oldPrice, newPrice,productLink}, index) => {
                return (
                  <div
                    key={index}
                    className="hide-box p-3 col-12 col-md-6 col-lg-4"
                  >
                    <div className="box bg-white">
                       <span onClick={()=> window.location.assign(`/details?id=${id}`)}>
                       {/* <HashLink to={`/details#first?id=${id}`} rel="noreferrer"> */}
                        <div className="image">
                          <img
                          loading="lazy"
                            className="img-fluid"
                            src={`imgs/${img}`}
                            alt="scooter"
                          />
                        </div>
  
                        <div className="text">
                          <span className="desc">{desc}</span>
                          <h3 className="title">{title}</h3>
                          <span className="subtitle text-center">{subtitle}</span>
                          <div className="price fw-bold">
                            <span className="old-price" dir="rtl">
                              {oldPrice}  ر.س 
                            </span>
                            <span className="new-price" dir="rtl">
                              {/* {formatCurrency(newPrice)} */}
                              { newPrice } 
                            </span>
                          </div>
                          {/* <button className="btn btn-outline-dark mt-2">
                          شراء المنتج
                        </button> */}
                        </div>
                      </span>
                      <div className="text pt-0">
                        <button
                          className="btn btn-dark fw-bold mt-2 position-relative"
                          onClick={() => {
                            window.open(productLink,"_self")
                          }}
                        >
                          الذهاب للمتجر
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  }
  