import React from 'react'
import Heading from './Heading'
import { Box } from './Box'
import { HashLink as Link } from 'react-router-hash-link'
import CategoryProducts from '../pages/CategoryProducts'


export default function Category() {
    
  return (
    <>
        <div className='category image'>
        <div className='container'>
          <Heading title="التصنيفات" desc="اختر المناسب"/>
          <div className='row boxes'>
           <Boxes img="helemt.webp" title="ادوات الامان" />
           <Boxes img="scooter-1.webp" title="سكوتر كهربائي"/>
           {/* <Boxes img="20.png" title="سلة"/> */}
           {/* <Boxes img="18.png" title="وقوف"/> */}
          </div>
        
            </div>
        </div>
  
    </>
  )
}


function Boxes({img,title},index){
  return(
    <div className='box' key={index}>
  <Link to={`/category?category=${title}#first`}>
    <div className='image'>
      <img loading="lazy" src={`./imgs/${img}`} alt="icon"/>
    </div>
      <h3  className='mt-3 d-block fw-bold text-center
      '>{title}</h3>
    </Link>
    </div>
  )
}
