import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import ParentSEO from "../components/ParentSEO";
export default function Policy() {
  return (
    <>
    <ParentSEO title={"سياسة الخصوصية"} content={" كسترافا يحرص بأقصى درجة إلى حماية بياناتك الشخصية"}>

      <Header />
      <Privcy />
      <Footer />
    </ParentSEO>
    </>
  );
}

function Privcy() {
  return (
    <div className="policy">
      <div className="logo-img"></div>
      <div className="content container text-end">
        <h1>سياسة الخصوصية</h1>
        <p>
          اكسترافا يحرص بأقصى درجة إلى حماية بياناتك الشخصية، ومن خلال
          قبولكم لسياسة الخصوصية الخاصة بعملاء اكسترافا فإنكم تسمحون لنا
          بجمع المعلومات الشخصية الخاصة بك وفقا لسياسة الخصوصية وبشكل محدود
          لعملياتكم على الموقع وإرسال العروض والمنتجات الجديدة عند الموافقة على
          ذلك وعليه لن يتم أبدا بيع أو استخدام أو عرض بياناتك الشخصية إلى أية
          جهة
        </p>
        <p>
          عند فتح حساب جديد على موقع اكسترافا ، نقوم بطلب مجموعة من البيانات
          الشخصية للعميل، حيث يجب إكمال تلك البيانات بطريقة صحيحة لإتمام عملية
          التسجيل على الموقع وللتأكد من عدم وجود أي معلومات كاذبة أو مضللة
        </p>
          <h2>جمع المعلومات الشخصية</h2>
          <p>
          عند فتح حساب جديد على موقعنا عبر منصة "سلة"، يتم طلب مجموعة من البيانات الشخصية للعميل. يجب إكمال تلك البيانات بطريقة صحيحة لإتمام عملية التسجيل على الموقع وللتأكد من عدم وجود أي معلومات كاذبة أو مضللة
          </p>
        <h2>إدارة البيانات</h2>
        <p>
        نحن نستخدم منصة "سلة" لإدارة بيانات العملاء وتنفيذ عمليات البيع. يتم تخزين ومعالجة بياناتك الشخصية على خوادم "سلة" والتي تلتزم بمعايير عالية من الأمان والخصوصية
        </p>
        <h2>التسعير و قبول الطلبات</h2>
        <p>
          في حال كان المنتج المعروض على الموقع مخالفا ً للوصف على الموقع، يمكنك
          حصرا ارجاع المنتج حسب سياسة الارجاع
        </p>
        <h2>معلومات التسعير</h2>
        <p>
        نحن نستخدم تفاصيل بطاقتك الائتمانية أو الخصم أو تفاصيل الحساب المصرفي فقط لإتمام عملية الدفع عبر منصة "سلة". يتم تأمين هذه المعلومات بشكل صارم ضد عمليات الاحتيال من خلال الإجراءات الأمنية المتاحة لدى "سلة"
        </p>
        <h2>معلومات مالية</h2>
        <p>
          بطاقة الائتمان / الخصم ، أو تفاصيل الحساب أو الإكتفاء بمزايا الموقع
          المتاحة في الدفع عند إتمام عملية التسجيل، يمكنك البدء في الشراء من
          اكسترافا المحمي بشكل صارم ضد عمليات الاحتيال والإيذاء من خلال
          الإجراءات الأمنية المتاحة لدينا
        </p>
        <h2>الاستخدام الداخلي للمعلومات الشخصية</h2>
        <p>
        نقوم بجمع وتخزين ومعالجة معلوماتك الشخصية على خوادم "سلة". نستخدم المعلومات الشخصية لتفعيل حسابك وتنفيذ العمليات وتوفير جميع الخدمات المطلوبة
        </p>
        <h2>الطلبات</h2>
        <p>
          يحتفظ اكسترافا بالحقّ في رفض أو إلغاء أية طلبية لأي سببٍ من
          الأسباب وفي أي وقتٍ من الأوقات وقبل أن نوافق على الطلبية قد نطلب منك
          تقديم معلومات أو تأكيدات إضافية، بما في ذلك ولكن ليس على سبيل الحصر،
          رقم الهاتف والعنوان
        </p>
        <h2>كيفية حماية بياناتك الشخصية</h2>
        <p>
        نلتزم بالتعامل مع معلومات حسابك بمستوى عالٍ من الأمن. نحمي بياناتك الشخصية ضد الوصول غير المصرح به أو الاستخدام أو الإفصاح. يتم تخزين تفاصيل بطاقة الائتمان/الخصم والحساب المصرفي في نموذج مشفر على خوادم "سلة". يجب عليك عدم مشاركة بيانات حسابك مع أي شخص. إذا كنت تشعر بأن أي من بياناتك الشخصية قد كشفت، يجب عليك تغييرها فورًا بعد تسجيل الدخول إلى حسابك
        </p>
        <h2>الاتصال</h2>
        <p>
        ذا كان لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية، يمكنك التواصل معنا عبر البريد الإلكتروني 
        <br/>
        <Link to="mailto:support@extrava.shop" className="text-primary underline">support@extrava.shop</Link>
        </p>
      </div>
    </div>
  );
}
