import React, { useContext, useEffect } from 'react'
import ParentSEO from '../components/ParentSEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLocation } from 'react-router-dom'
import { IdContext, IdProvider } from '../context/IdContext'
import items from "../data/product.json";
import { HashLink } from 'react-router-hash-link'
import Error404 from './Error404'
export function Details() {
  
  let location = useLocation()
  // let numberId = location.search[location.search.length-1];
  let searchParams = new URLSearchParams(location.search);
  let numberId = searchParams.get("id");
  
  const { id, setId } = useContext(IdContext);
  useEffect(()=> {
    setId(items[numberId-1])
  },[setId,id,numberId])

    useEffect(()=> {
      console.log(numberId)
    })
  
  return (
  <>
    {!id ?<Error404/>:
      <>
      <ParentSEO title={"المزيد من التفاصيل"} content={"مميزات واخصائص آمن السكوترات مع قيادة بكل رفاهية"} img={id.img}>
   <Header/>
    <div className='details container remove-shadow py-3'>
        <div className='row p-0 flex-row-reverse justify-content-center'>
            <div className='col-12 col-md-6 p-0'>
                <ImagePart/>
            </div>
            <div className=' py-2 px-4 col-12 col-md-6 p-0'>
                <DetailsPart/>
                
              
                {/* <MoreDetails/> */}
            </div>
        </div>
    </div>
        <Footer/>
        
    </ParentSEO>
      </>
  }
</>  
  
    
  )
}


function ImagePart() {
  const { id } = useContext(IdContext);
    return(
        <div className='image-part'>
            <div className='image'>
                <img loading="preload" src={`./imgs/${id.img}`} alt="سكوتر كهربائي"/>
            </div>
        </div>

    );
}

function Tamara() {
  
  const { id } = useContext(IdContext);
  return ( 
    <div className='tamara border border-1 gap-2 align-items  -center rounded d-flex py-2 px-3'>
      <img loading="lazy"  src="./imgs/tamara.png" style={{width:"100px", height:"30px"}} alt="تمارا-لوجو" />
      <div className='text'>
        <p className='p-0'>أو قسم فاتورتك بقيمة {(id.newPrice/4).toFixed(2)} ر.س على 4 دفعات بدون رسوم تأخير، متوافقة مع الشريعة الإسلامية</p>
      </div>
    </div> 
  )
}

function Adult() {
  
  const { id } = useContext(IdContext);
  
  return(
  <div className='adult'>
      {id.usefor.map(e => {
        return <h2 key={e}> {e} </h2>
      })}
            
    </div>
  
)
}

function DetailsPart(){ 
  
  const { id } = useContext(IdContext);
  
    return(
        <div className='details-part text-end'>
            <h3 className="product fs-b4 fw-bold">{id.title}</h3>
      <div className="title fs-4 pb-1"> :التقييم</div>
      {/* Evaluation */}
      <div className="evaluation d-flex gap-1 justify-content-end align-items-start">
        <img loading="lazy"  src="imgs/star.png" className="" alt="star" />
        <img loading="lazy"  src="imgs/star.png" className="" alt="star" />
        <img loading="lazy"  src="imgs/star.png" className="" alt="star" />
        <img loading="lazy"  src="imgs/star.png" className="" alt="star" />
        <img loading="lazy"  src="imgs/star.png" className="" alt="star" />
      </div>

      <div className="more-details d-flex flex-column py-2">
        <div className="price">
          <span className="new-price" dir="rtl">
            {id.newPrice}ر.س
          </span>
        </div>
        
        <div className='d-flex justify-content-end align-items-center my-2'>
        <h6 className="text-success text-end m-0 px-1">متوفر</h6>
        <div className='image correct d-flex align-items-center justify-content-center'>
        {/* <video width="25" height="25" autoplay loop muted playsinline>
          <source src="./imgs/correct.mp4" type="video/mp4"/>
      </video> */}
          <img loading="lazy"  src="./imgs/correct.gif" alt="correct" style={{width:"25px",height: "25px"}}/>
          
        </div>
        </div>
        
        <ProductQuantity/>
        <Tamara/>
        <Adult/>
        <ProductDetail/>
        <ClientNeed/>
        <BuyNow/>
      </div>
        </div>
    )
}


function ProductQuantity() {
  
  const { id } = useContext(IdContext);
  
  return(
    <p className='m-0 mb-2'>
    <b> الكمية المتبقية: </b> 
    <span> {id.quantity} قطع </span>
     
   </p>
  ) 
}


function ProductDetail() {
  
  const { id } = useContext(IdContext);
  return(
    <div className='product-details my-3 d-flex gap-1 justify-content-center align-items-center'>
       <img loading="lazy"   src="./imgs/icons/shaild.png" alt="shield"  style={{width:"25px", height: "25px"}}/>
    <b className='text-end  d-block'>{id.desc}</b>
    {/* <p className='text-end'>{id.subtitle}</p> */}
    </div>
     )
}

function BuyNow() {
  
  const { id } = useContext(IdContext);
  return(
    
    <div>
      <HashLink to={id.productLink}>

      <button className='btn btn-dark w-100 fw-bold' style={{color:"white",backgroundColor:"#CC5500",border:"none"}}>أعرف المزيد</button>
      </HashLink>
    </div>
  )
}

 

function ClientNeed() {
  
  const { id } = useContext(IdContext);
  const clientWant = id.clientNeed;

  return(
    <div className='details-client'>
      {!clientWant ? "":
      <>
      { clientWant.map(({title,desc},index)=> {
        return <div key={index} className=''>
        <p className=''>
         <b> {title} </b> 
          <span> {desc} </span>
          
        </p>
        </div>
    })}
    </>
      }
    </div>
  ) 
}

function MoreDetails() {

  let details = [{userBenefit:[`<b>اداء قوي ومتطور:</b> استمتع بقيادة مليئة بالرفاهية والقوة، بفضل الأداء المتفوق لسكوتر كينج سونج ان 13 ماكس ، الذي يأخذك إلى وجهتك بكل أمان.`]}]
  
  
  
  return(<div className='more-details text-end'>
     <b>اداء قوي ومتطور</b>
    <p>استمتع بقيادة مليئة بالرفاهية والقوة، بفضل الأداء المتفوق لسكوتر كينج سونج ان 13 ماكس، الذي يأخذك إلى وجهتك بكل أمان.</p>

    <b>سهولة الحمل والتخزين</b>
    <p>بفضل التصميم القابل للطي، يمكنك حمل السكوتر وتخزينه بسهولة تامة، مما يجعله صديق مثالي لكل مشوار.</p>

    <b>تحسين الإنتاجية اليومية</b>
    <p>بفضل السرعة والراحة التي يوفرها السكوتر، يمكنك إنجاز الكثير من المهام خلال يومك دون إضاعة الوقت في التنقلات الطويلة.</p>

    <h4>اللون:</h4>
    <p>الأسود</p>

    <h4>مزود الخدمة:</h4>
    <p>KingSong</p>

    <h4>المنتج:</h4>
    <p>شينزن</p>

    <h4>الضمان:</h4>
    <p>سنتين</p>

    <b>الخصائص</b>
    <ul>
        <li>السرعة القصوى: 40 كم / ساعة</li>
        <li>المدى: 65 كم</li>
    </ul>

    <b>المواصفات</b>
    <ul>
        <li>محرك: 48 فولت، 600 واط</li>
        <li>بطارية: ليثيوم أيون 48 فولت، 14.5 أمبير/ساعة</li>
        <li>مدة الشحن: 5 - 6 ساعات</li>
    </ul>

    <b>المكونات</b>
    <ul>
        <li>أضواء: أضواء أمامية محسنة وخلفية LED مع مؤشرات تنبيه للسيارات الأخرى</li>
        <li>فرامل: فرامل قرصية خلفية وفرامل أسطوانة، بالإضافة إلى نظام E-ABS</li>
        <li>نظام الفرامل: نظام E-ABS وفرامل قرصية وأسطوانة، مما يوفر كبح آمن وسريع</li>
    </ul>

  </div>
  )
}