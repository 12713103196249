import React from 'react'
import ExtravaButton from '../components/ExtravaButton'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ParentSEO from '../components/ParentSEO'

function SendTo() {
  return (
    <ParentSEO title="إرسال البريد الإلكتروني" content="كن اول من يعلم ">
    <Header/>
    <div className="about error position-relative text-end  pb-4  px-3">
      <div className='d-flex justify-content-start'>
        <div className='image'>
          <img src="./imgs/extrava-white.png" alt="logo"/>
  
        </div>
      </div>
      <span className=''>سيدي العميل</span>
      <h1 className='mt-4 d-flex align-items-center gap-2 fw-bold justify-content-end '>
        <span className="mt-2" >
                تم إضافتك  بنجاح
        </span>
         <img src="./imgs/icons/heart.png" alt="warning"/>
        </h1>
      <p className="mt-3">ستتلقى بريدا ترحيبيا </p>
      <p>نحن فخورون بثقتك. شكرًا لإختيارك اكسترافا </p>
  
      <div className='text-center mt-3 d-flex justify-content-center'>
      {<ExtravaButton link="/#first" icon={"home.png"} title='العودة للصفحة الرئيسية' type="home"/>}
      </div>
  
  
      </div>
  
    {/* <div className="error position-relative text-end py-3 px-3">
      <h1 className="">هذي الصفحة غير متوفرة </h1>
      
    </div> */}
    <Footer/>
    </ParentSEO>
  )
}

export default SendTo