import React from 'react'
import ParentSEO from '../components/ParentSEO'
import Header from '../components/Header'
import ExtravaButton from '../components/ExtravaButton'
import Footer from '../components/Footer'
import Heading from '../components/Heading'

function Return() {
 
  let letters = [
    {title:"سياسة الاسترجاع والضمان", desc:"نحن نسعى لتقديم أفضل المنتجات بأعلى جودة لعملائنا. لذا نقدم لكم سياسة استرجاع مرنة وداعمة لضمان رضاكم التام"},
    {title:"سياسة الاسترجاع", desc:"يمكن إرجاع المنتجات خلال 5 ايام  من تاريخ الشراء بشرط أن تكون غير مستخدمة وفي حالتها الأصلية، مع إرفاق الفاتورة الأصلية. يجب أن تكون جميع الملحقات والعبوة الأصلية مرفقة مع المنتج المسترجع"},
    {title:"المنتجات ذات الضمان", desc:` بعض المنتجات التي نعرضها تأتي مع ضمان لمدة سنتين . إذا واجهت أي عيوب تصنيعية أو مشاكل في الأداء خلال فترة الضمان، يرجى التواصل معنا لتقديم الدعم اللازم. يشمل الضمان الإصلاح أو الاستبدال حسب المصنع.
الضمان لا يشمل الأضرار الناتجة عن سوء الاستخدام أو الحوادث
`},


    {title:"كيفية التقديم للاسترجاع أو الصيانة", desc:"يرجى التواصل مع فريق خدمة العملاء على البريد الإلكتروني في صفحة تواصل معنا, تقديم طلب الاسترجاع أو الصيانة"},
  
  
  ]

  return(
    <ParentSEO title="سياسة الاسترجاع" content="نحن نهتم بك. نحن هنا لخدمتك ونتطلع لضمان رضاك التام عن تجربتك معنا ">
    <Header/>
  <div className="about position-relative text-end  pb-4  px-3">
    <div className='d-flex justify-content-start'>
      <div className='image'>
        <img src="./imgs/extrava-white.png" alt="logo"/>

      </div>
    </div>
    <Heading  title="سياسة الاسترجاع"desc="نحن نهتم بك"/>
   {/* Title 1 */}
   <div className='my-3'>
      {letters.map(e=> {
        return (
          <>
        <h2>{e.title}</h2>
        <p>{e.desc}</p>
          </>
        )
      })}
     </div>
     <h3 className='fw-bold fs-4 text-center my-5'>نحن هنا لخدمتكم ونتطلع لضمان رضاكم التام عن تجربتكم معنا</h3>
    <div className='text-center mt-3 d-flex justify-content-center'>
        {<ExtravaButton link="/#first" title='العودة للصفحة الرئيسية' icon={"home.png"} type="home"/>}
    </div>
  </div>
  <Footer/>
    </ParentSEO>
  )
}

export default Return
