import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashLink as Link } from 'react-router-hash-link'


export default function Header() {
  return (
    <>
     <header className="bg-white py-3 shadow" id="first">
      <div className='container d-flex flex-row-reverse flex-wrap justify-content-between align-items-center p-3'>

          <div className='col-12 col-sm-6'>
            <Logo/> 
          </div>
          <div className='col-12 col-sm-6'>
            <Navs/>
          </div>
      </div>
  </header>
  </>
  )
}

function Logo() {
    return (
    <div>
      <div className="logo d-flex flex-row-reverse align-items-center justify-content-center">
      <img  style={{ width: '50px', height: '50px' }} className='img-fluid' src="imgs/extrava-logo.svg" alt="اكسترافا" />
        {/* <span style={{fontSize: "var(--font-size-large)"}}>اكسترافا</span> */}
      </div>
    
  </div>
)
}



function Navs(){
  return(

    <nav  className=' d-flex justify-content-center align-items-center '>
  <ul style={{fontSize:"var(--font-size-base)"}} className='d-flex m-0 p-0 fw-bold flex-row-reverse gap-3 '>
    <li><Link to="/">الرئيسية</Link></li>
    <li><Link to="/scooters">التصنيفات</Link></li>
    <li><Link to="/about">من نحن</Link></li>
    <li><Link to="/contact">تواصل معنا</Link></li>
    {/* <li><Link to="/contact">الاستخدام الآمن</Link></li> */}
    {/* <li><a href="/contact">خدمات ما بعد البيع</a></li> */}
  </ul>
</nav>
  )
}