import React, { createContext, useState } from 'react';

// إنشاء الـ Context
export const IdContext = createContext();

// إنشاء Provider لجعل الـ id متاحاً في المكونات الفرعية
export const IdProvider = ({ children }) => {
  const [id, setId] = useState(null);  // id هنا نضع قيمة

  return (
    <IdContext.Provider value={{ id, setId }}>
      {children}
    </IdContext.Provider>
  );
};