import React from 'react'
import { HashLink } from 'react-router-hash-link'

export default function ButtonHref({href,content=" الذهاء الى المتجر"}) {
  return (
    <div className='button-href'>
        <HashLink to={"https://salla.sa/extrava"}>
          <button>
            <span>{content}</span>
            <img rel="preload" src='./imgs/icons/arrow.webp'  alt="arrow"/>
          </button>
        </HashLink>
    </div>
  )
}
