import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Category from '../components/Category'
import ParentSEO from '../components/ParentSEO'

function Scooters() {
  return (
    <>
    <ParentSEO title={"التصنيفات"} content={"اختر التصنيف المناسب وتمتع برفاهية وامان اكبر"}>

      <Header/>
      <Category/>
      <Footer/>
    </ParentSEO>
    </>
  )
}

export default Scooters