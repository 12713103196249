import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ButtonHref from "../components/ButtonHref";
import ExtravaButton from "../components/ExtravaButton";
import ParentSEO from "../components/ParentSEO";

export default function Error404() {
  return(
  <ParentSEO title="خطأ" content="خطأ في الصفحة">
  <Header/>
  <div className="about error position-relative text-end  pb-4  px-3">
    <div className='d-flex justify-content-start'>
      <div className='image'>
        <img src="./imgs/extrava-white.png" alt="logo"/>

      </div>
    </div>
    <span className=''>سيدي العميل</span>
    <h1 className='d-flex align-items-center fw-bold justify-content-end '>
      <span className="mt-2">

       الصفحة غير متوفرة
      </span>
       <img src="./imgs/icons/warning.png" alt="warning"/>
      </h1>
    <p className="mt-3">يؤسفنا, ان الصفحة التي تبحث عنها غير موجودة    </p>
    <p>اذا كنت تعتقد ان هنالك مشكلة, تواصل مع الدعم الفني </p>

    <div className='text-center mt-3 d-flex justify-content-center'>
    {<ExtravaButton link="/#first" icon={"home.png"} title='العودة للصفحة الرئيسية' type="home"/>}
    </div>


    </div>

  {/* <div className="error position-relative text-end py-3 px-3">
    <h1 className="">هذي الصفحة غير متوفرة </h1>
    
  </div> */}
  <Footer/>
  </ParentSEO>)
  
}
