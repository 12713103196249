import React, { useEffect } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async';

export default function ParentSEO({children,title,content,img}) {
  return (
    <HelmetProvider>
    <Helmet>
    <link rel="preload" href="./../../public/fonts/Extrava/Extrava.ttf'" as="font" type="font/ttf" crossorigin="anonymous"/>

      {img && 
    <link rel="preload" as="image" href={`./imgs/${img}`} />}
      <title>اكسترافا - {title}</title>
      <meta name="description" content={content} />
    </Helmet>
        {children}
    </HelmetProvider>
    
  )
}