import React from 'react'
import Heading from './Heading'
import ExtravaButton from './ExtravaButton'

export default function EmailArticle() {

  let handleInvalid = (event) => {
    event.preventDefault();

    event.target.setCustomValidity('يرجى إدخال بريد إلكتروني صالح يحتوي على الرمز @');
  };

  let handleInput = (event) => {
    // إزالة الرسالة المخصصة بمجرد أن يبدأ المستخدم في الكتابة
    event.target.setCustomValidity('fffffff');
  };
  
  
    return(
      <form action="../PHP/insert.php" method="POST">
        
        <div className='email-article'>
            <div className='container'>
      
            <Heading title="سجل بريد الإلكتروني" desc="كن أول من يعلم"/>
            <div className='form p-2'>
              
              
              <input type='email' required name="email" className='form-control' placeholder='example@mail.com'/>
            
              <Benefits/>
              
            </div>
              <div className='text-center my-4 d-flex justify-content-center'>
                <ExtravaButton link="" style={{width:"150px",borderRadius:"12px"}} icon={"mail.png"} type="home" title='سجل الآن'/>
              </div>

              
              
            </div>
        </div>
        
      </form>
        )
}


function Benefits() {
  return(
    <div className='my-3'>
    <h3 className='fs-5 text-end' dir='rtl'>تتضمن رسائل البريد:</h3>
      <ul className='d-flex flex-column text-end px-5' dir='rtl'>
        <li style={{listStyleType:"disc"}} className='text-end  '>  قوانين المرور في المملكة العربية السعودية</li>
        <li style={{listStyleType:"disc"}} className='text-end  '>طرق الحماية والأمان</li>
        <li style={{listStyleType:"disc"}} className='text-end  '>آخر المنتجات الجديدة</li>
        <li style={{listStyleType:"disc"}} className='text-end  '>مقالات تخص آخر اخبار السكوترات المناسبة لك</li>
      </ul>
   
    </div>
  )
}
