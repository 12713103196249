import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ExtravaButton from '../components/ExtravaButton'
import ParentSEO from '../components/ParentSEO'

export default function About() {
  let charter_goals = [
    {name:"الريادة في تقديم تجربة تنقل فاخرة",desc:": نهدف إلى تقديم تجربة تنقل تجمع بين الأمان والرفاهية، مما يضيف لمسة من الفخامة إلى حياتك اليومية"},
    {name:"جودة استثنائية",desc:": نسعى لتوفير سكوترات عالية الجودة لتلبية احتياجاتك اليومية بكفاءة وراحة"},
    {name:"خدمة العملاء",desc:": نسعى لبناء علاقة ثقة مع عملائنا من خلال تقديم خدمات مميزة تلبي توقعاتهم وتفوقها"},
    {name:"التطوير المستمر",desc:": نحرص على التطوير المستمر لمنتجاتنا وخدماتنا لتواكب أحدث التقنيات وتلبي رغبات العملاء المتجددة"}
  ]
  let charter_values= [
    {name:"البساطة والفخامة",desc:": نصمم تجارب تنقل تجمع بين البساطة والفخامة لتعزيز رفاهيتك"},
    {name:"التفوق في الخدمة",desc:": نسعى لتقديم خدمة عملاء تفوق توقعاتك في كل مرة"},
    {name:"الجودة الفائقة",desc:": نحرص على تقديم أعلى مستويات الجودة لضمان راحتك وأمانك"},
  ]

  let charter_mission = [
    
    {name:"البحث والتطوير المستمر",desc:": نستثمر في البحث والتطوير لتقديم حلول تنقل مبتكرة تلبي احتياجات السوق المتغيرة وتفوق توقعات العملاء"},
    {name:"تعزيز الاستدامة البيئية",desc:": نحرص على تبني ممارسات مستدامة في جميع جوانب عملنا، من خلال استخدام مواد صديقة للبيئة وتقنيات تقلل من الأثر البيئي"},

  
  ]
  return (
    <ParentSEO title={"من نحن"} content=" 
    قصة اكسترافا, قيمنا, اهدافنا, مهامنا. نسعى في ان نستثمر في البحث والتطوير لتقديم حلول تنقل مبتكرة تلبي احتياجات السوق المتغيرة وتفوق توقعات العملاء">
    <Header/>
  <div className="about position-relative text-end  pb-4  px-3">
    <div className='d-flex justify-content-start'>
      <div className='image'>
        <img src="./imgs/extrava-white.png" alt="logo"/>

      </div>
    </div>
    <span className=''>قصة اكسترافا</span>
    <h1 className='d-flex align-items-center fw-bold justify-content-end '>
      من نحن
      </h1>
    <p>نسعى في اكسترافا لأن نكون جزءاً من حياتك اليومية، لنساعدك على التنقل بأسلوب يعكس رفاهيتك وراحتك</p>
    {/* Goals */}
    {ourCharter("اهدافنا","goals.png",charter_goals)}
    {/* Values */}
    {ourCharter("قيمنا","values.png",charter_values)}
    {/* Mission */}
    {ourCharter("مهامنا","mission.png",charter_mission)}
    <div className='text-center mt-3 d-flex justify-content-center'>
        {<ExtravaButton link="/#first" title='العودة للصفحة الرئيسية' icon={"home.png"} type="home"/>}
    </div>
  </div>
  <Footer/>
    </ParentSEO>
  )
}


function ourCharter(title,icon,obj) {


  return(
    <div className='my-3'>
    <div className='d-flex align-items-center fw-bold justify-content-end gap-2 mb-2'>
    <h2 className='m-0 mt-2'>{title}</h2>
    <img style={{width:"40px"}} src={`./imgs/icons/${icon}`} alt={title}/>
    </div>
    <div className='d-flex flex-column gap-0'>
      {obj.map((e)=> {
        return <p><b>{e.name}</b>{e.desc}</p>

      })}
    </div>
    </div>
    
  )
  
}