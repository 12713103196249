import React, { useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import ExtravaButton from './ExtravaButton'
import Heading from './Heading'
import ParentSEO from './ParentSEO'

function Contact() {
    return (
      <ParentSEO title={"تواصل معنا"} content="هل لديك اي سؤال ؟  إن كان لديك اي مشكلة في طلبك سنقوم بمساعدك في هذا الموضوع">
    <Header/>
    <div className="contact-us about position-relative text-end  pb-4  px-3">
      <div className='d-flex justify-content-start'>
        <div className='image'>
          <img src="./imgs/extrava-white.png" alt="logo"/>
  
        </div>
      </div>
      <div className='mt-5'>

      <Heading  title="يسعدنا التواصل معك  "desc="هل لديك سؤال ؟"/>
      </div>
      
      <FormPage/>
      
      <div className='text-center mt-3 d-flex justify-content-center'>
          {<ExtravaButton link="/#first" type="home" title='العودة للصفحة الرئيسية' icon={"home.png"} />}
      </div>
    </div>
   
    <Footer/>
    </ParentSEO>
  )
}

export default Contact



function FormPage() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        // event.preventDefault();

        // const mailtoLink = `mailto:support@extrava.shop?subject=${encodeURIComponent("تواصل عن طريق الفورم")}&body=${encodeURIComponent(message)}%0D%0A%0D%0AFrom: ${email}`;
        
        // window.location.href = mailtoLink;
        console.log("ONE")
      };
    
    
    
    
    return (
        <section className="contact">
          <div className="container">
            <div className="row" id="contact">
              {/* Form */}
              <div className="col-12 col-md-6 ">
                <div className='lazy text-center'>
                <h3 className='fw-bold'> تواصل معنا عبر الواتس او البريد الاكتروني</h3>
                <br/>
                <br/>
                <a href="mailto:support@extrava.shop" className='fw-bold fs-5 text-primary'>
                support@extrava.shop
                </a>
                <br/>
                <a  className='fw-bold fs-5 text-primary'  href="https://wa.me/966508630084">
                  الواتس آب 
                </a>
                <br/>
                <br/>
                </div>
                {/* FORM */}
                {/* <form
                //   onSubmit={(e) => sendEmail(e)}
                  className="z-index-1 gap-4 d-flex flex-column align-items-center justify-content-center p-5 pt-2 px-4"
                >
                  <div className="w-100 d-flex flex-column gap-2">
                    <span>الإسم</span>
                    <input
                      required
                      className="form-control px-3 py-2"
                      type="text"
                      placeholder="الاسم"
                      name="from_name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="w-100 d-flex flex-column gap-2">
                    <span>البريد الإكتروني</span>
                    <input
                      required
                      className="form-control px-3 py-2"
                      type="email"
                      placeholder="البريد الإكتروني"
                      name="from_email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="w-100 d-flex flex-column gap-2">
                    <span>الرسالة</span>
                    <textarea
                      required
                      className="form-control px-3 py-2"
                      cols="30"
                      rows="5"
                      placeholder="الرسالة"
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className='d-flex align-items-center justify-content-center'>
                  <ExtravaButton title="إرسال للدعم الفني" icon="arrow-2.png" func={handleSubmit}  />
                  </div>
                </form> */}
              </div>
    
              {/* Explain */}
              <div className="explain col-12 col-md-6 d-flex py-5 p-2">
                <div className="container d-flex flex-column align-items-center gap-4">
                  {/* Box */}
                  <div className=" d-flex align-items-center gap-3">
                    <img alt="explain" src="imgs/hands.png" />
                    <div className="text text-end">
                      <h4>نقدم لك المساعدة</h4>
                      <p>
                        إن كان لديك اي مشكلة في طلبك سنقوم بمساعدك في هذا الموضوع
                      </p>
                    </div>
                  </div>
                  {/* Box */}
                  <div className=" d-flex align-items-center gap-3">
                    <img alt="explain" src="imgs/help.png" />
                    <div className="text text-end">
                      <h4>للتواصل</h4>
                      <p>
                        يمكنك التواصل معنا عبر الفورم بإدخال البريد الإكتروني و
                        الإسم و سيتم الرد في اقرب وقت
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
}