import React from 'react'
import ParentSEO from '../components/ParentSEO'
import Header from '../components/Header'
import ExtravaButton from '../components/ExtravaButton'
import Footer from '../components/Footer'
import Heading from '../components/Heading'

function Soon({title}) {
  return (
    <ParentSEO title="قريبا" content="قادمة قريبا ">
  <div className="about position-relative text-end  pb-4  px-3">
    <div className='d-flex justify-content-start'>
      <div className='image'>
        <img src="./imgs/extrava-white.png" alt="logo"/>

      </div>
    </div> 
    <Heading  title="أدوات الامان" desc="قادمة"/>
    <h2 style={{fontFamily:"Extrava"}} className='text-center display-1 fw-bold'>{title}</h2>
    <div className='text-center mt-5 d-flex justify-content-center'>
        {<ExtravaButton link="/#first" title='العودة للصفحة الرئيسية' icon={"home.png"} type="home"/>}
    </div>
  </div>
    </ParentSEO>
  )
}

export default Soon