import React from "react";
import { HashLink, HashLink as Link } from 'react-router-hash-link'
 
export default function Footer() {
  let date = new Date(),
    dateYear = date.getFullYear();

  return (
    <>
    <footer className=" p-5" style={{ backgroundColor: "#fafafa" }}>
      <div className="container">
        <div className="row flex-row-reverse">
          {/* Box */}
          <div className="box col-12 col-md-6 col-lg-6 text-end ps-5">
            <h4 className="fw-bold">اكسترافا</h4>
            <p className="mt-3">
            نسعى في اكسترافا لأن نكون جزءاً من حياتك اليومية، لنساعدك على التنقل بأسلوب يعكس رفاهيتك وراحتك
            </p>
            <p>
              {/* لتجربة تنقل مميزة تضيف لمسة من الفخامة إلى يومك */}
              </p>
          </div>
          {/* Box */}
          <AllLinks/>
        </div>
      </div>
      <div className="p-2 text-center container">
        <div className="border border-1 my-3"></div>© <span>{dateYear} </span>
          Extrava.shop جميع الحقوق محفوظة لدى  موقع  
      </div>
    </footer>
     <SubFooter/>
      </>
  );
}

function SubFooter() {
  return(
    <div className="sub-footer gap-3 bg-white px-5 d-flex align-items-center">
      {/* <h6>صنع بواسطة إكسترافا </h6> */}
    <div className="images d-flex gap-3">

    <HashLink to="https://eauthenticate.saudibusiness.gov.sa/certificate-details/0000134024">
      <img src="./imgs/center-business.png" alt="مركز الاعمال"/>
    </HashLink>
    <div >
    <img src="./imgs/tamara.jpg" alt="tamara"/>
    </div>

    
    <div >
    <img src="./imgs/apple-pay.png" alt="apple pay"/>
    </div>
    
    
    
    <div >
      <img src="./imgs/visa.png" alt="visa and master card"/>
    </div>

    
   


    <div >
      <img src="./imgs/mada.png" style={{height:"15px"}} alt="mada pay"/>
    </div>

    </div>
  </div>

  );
}


function AllLinks() {

  let linkPolicy = [
    {name:" الساسية و الخصوصية", link:"policy"},
    // {name:"معلومات الشحن", link:"transport"},
    {name:"سياسة الإسترجاع",link:"return"},
  ]

  return (
    <>
           <div className="box  col-12 col-md-6 col-lg-3 text-end">
            <h4 className="fw-bold">الروابط </h4>
            <ul className="d-flex flex-column gap-3 mt-3">
              {linkPolicy.map(({name,link},index) =>{ 
                return  <li key={index}>
                <Link className="" to={`/${link}#first`}>
                  {name}
                </Link>
              </li>
              })}
              
            </ul>
          </div>
          <div className="box col-12 col-md-6 col-lg-3 text-end">
            <h4 className="fw-bold">تواصل معنا </h4>
            <ul className="d-flex flex-column gap-3 mt-3">
              <li>
                <Link className="" to="https://wa.me/966508630084">
                الواتس آب 
                </Link>
              </li>
              {/* <li>
                <Link className="" to="https://wa.me/966508630084">
                انستقرام  
                </Link>
              </li> */}
              
            </ul>
          </div>
    </>
  )
}
