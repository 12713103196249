import React, { useEffect } from 'react'
import Header from '../components/Header'
import { Box } from '../components/Box'
import { Product } from '../components/Product'
import ButtonHref from '../components/ButtonHref'
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ParentSEO from '../components/ParentSEO';
import Footer from '../components/Footer'
import { useLocation } from 'react-router-dom'
import Soon from './Soon'

function CategoryProducts() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    
  return (
    <>
    <Header/>
        {category === "سكوتر كهربائي"?<Product sectionTitle={category} desc="الافضل"/>:<Soon title="قريبا"/>}
        
    <Footer/>
        
    </>
  )
}

export default CategoryProducts