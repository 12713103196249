import React from 'react'


export function Box({imgIcon,content}) {
  return (
        <div className='box bg-white image shadow d-flex flex-column align-items-center justify-content-center'>
        <img loading='lazy' src={`./imgs/icons/${imgIcon}`}  alt="icon"/>
         
          <span style={{fontSize:"var(--font-size-base)"}} className='fw-bold'>{content}</span>
        
        </div>
  )
}
